import './assets/css/main.scss'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import cookies from 'js-cookie'
import VueGtag from "vue-gtag";

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

Vue.use(VueGtag, {
  config: { id: "G-530GJF74Y6" }
});

window.axios = require('axios')
require('./plugins');

window.io = require('socket.io-client')

import { mixins } from './mixins';
Vue.mixin(mixins);

Vue.config.productionTip = false

import DefaultLayout from './layouts/DefaultLayout.vue'
import AuthLayout from './layouts/AuthLayout.vue'

Vue.component('default-layout', DefaultLayout)
Vue.component('auth-layout', AuthLayout)

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.state.token) {
    if ( from.name == 'Login' ) {
      return next({name:'Login'});
    } else {
      const token = cookies.get('x-access-token')
      if (token) {
        const twoFaRequired = cookies.get('2fa-required')
        if (twoFaRequired && twoFaRequired == 'true') {
          return next({name:'Login'});
        } else { 
          store.dispatch('setTokenFromCookie', { token })
        }
      } else {
        return next({name:'Login'});
      }
    }
    
  }
  return next();
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
