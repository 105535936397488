<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close"/>
    </div>
    <div class="modal__header">
      <div class="sbttl">
        Contact us
      </div>
    </div>
    <div class="modal__body">
      <div class="row">
        <div class="label">Contact person</div>
        <div class="data">
          <select name="person_type" class="inpt" v-model="form.person_type">
            <option value="owner">Owner</option>
            <option value="agent">Agent</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="label">Price</div>
        <div class="data">
          <input type="text" name="price" class="inpt" v-model="form.price">
        </div>
      </div>
      <div class="row">
        <div class="label">WhatsApp/Telegram/WeChat</div>
        <div class="data">
          <input type="text" name="messengers" class="inpt" v-model="form.messengers">
        </div>
      </div>
      <div class="row">
        <div class="label">LinkedIn/Facebook</div>
        <div class="data">
          <input type="text" name="social" class="inpt" v-model="form.social">
        </div>
      </div>
      <div class="row">
        <div class="label">E-mail *</div>
        <div class="data">
          <input type="text" name="email" class="inpt" v-model="form.email">
        </div>
      </div>
      <span v-if="error.length > 0" class="invalid-feedback">{{ error }}</span>
      <div class="modal__header">
        <div class="sbttl">
          Create Business Offer
        </div>
      </div>
      <div class="row">
        <div class="label">Type of Business</div>
        <div class="data">
          <input type="text" name="business_type" class="inpt" v-model="boForm.business_type">
        </div>
      </div>
      <div class="row">
        <div class="label">Type of License</div>
        <div class="data">
          <input type="text" name="license_type" class="inpt" v-model="boForm.license_type">
        </div>
      </div>
      <div class="row">
        <div class="label">Country</div>
        <div class="data">
          <select class="inpt" style="width: 100%;" v-model="boForm.country_id">
            <option value="null">Select country</option>
            <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="label">Description</div>
        <div class="data">
          <textarea name="description" rows="5" class="inpt inpt__ta" v-model="boForm.description"/>
        </div>
      </div>
      <div class="row row__price">
        <div class="label">Price</div>
        <div class="data">
          <input type="text" name="price" class="inpt" v-model="boForm.price">
          <select v-if="currencies" v-model="boForm.currency_id">
            <option
              v-for="c in currencies" :key="c.id"
              :value="c.id"
              v-text="c.code"
            />
          </select>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="submit">Send</div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  props: {},
  data: () => ({
    allCurrencies: null,
    countries: [],
    form: {
      person_type: null,
      price: null,
      messengers: null,
      social: null,
      email: null,
    },
    boForm: {
      business_type: null,
      license_type: null,
      country_id: null,
      description: null,
      price: null,
      currency_id: 2
    },
    error: ''
  }),
  created () {
    this.getCurrencies()
    this.getCountries()
  },
  computed: {
    currencies () {
      if (!this.allCurrencies) return []
      return this.allCurrencies.filter(el => el.code === 'EUR' || el.code === 'USD')
    }
  },
  methods: {
    getCurrencies () {
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/data/currencies')
        .then(({ data }) => {
          this.allCurrencies = data
        })
        .catch(err => console.dir(err))
    },
    getCountries() {
      axios.get(process.env.VUE_APP_BACKEND_API_URL + 'admin/data/countries')
        .then(({ data }) => {
          this.countries = data
        })
        .catch((error) => console.log(error))
    },
    async submit() {
      if (this.form.email === null || this.form.email.length === 0) {
        this.error = 'Please, enter e-mail'
        return
      }

      let success = {
        feedback: false,
        offer: false,
      }

      await axios.post(process.env.VUE_APP_BACKEND_API_URL + 'business-feedback', this.form)
        .then(({ data }) => {
          if (data) {
            this.close()
            success.feedback = true
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })

      await axios.post(process.env.VUE_APP_BACKEND_API_URL + 'user-business-offer', this.boForm)
        .then(({ data }) => {
          if (data) {
            this.close()
            success.offer = true
          }
        })
        .catch((errors) => {
          console.dir(errors)
        })

      if (success.feedback && success.offer) {
        this.$noty.success('You successfully send message')
      }
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }

  &__close {
    .btn::before {
      content: '+';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      color: white;
      font-size: 2.25rem;
      font-weight: 300;
    }
  }

  &__body {
    flex-direction: column;

    .row {
      width: 100%;
      display: flex;
      align-items: center;
      margin: .5rem 0;

      &__price {
        .data {
          display: flex;

          input {
            margin-right: .75rem;
          }

          select {
            color: white;
            background-color: var(--c_more-black);
            padding: .25rem .5rem;
            border-radius: 6px;
          }
        }
      }
    }

    .label {
      // min-width: 10rem;
      font-weight: bold;
      padding-right: 1rem;
    }

    .data {
      .inpt {
        color: var(--c_black);
        outline: none;
        -webkit-appearance: none;
      }
    }

    .invalid-feedback {
      width: 100%;
      margin-top: 0.25rem;
      font-size: 80%;
      color: #dc3545;
    }

    // .form-control {
    //   width: 100%;
    //   height: 100%;
    //   color: #ffffff;
    //   background-color: #1C2126;
    //   padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    //   margin: auto 0rem;
    //   border: 1px solid #2F363D;
    //   border-radius: 0.75rem;
    //   box-shadow: 0px 11px 23px rgb(0 0 0 / 2%);
    //   outline: none;
    //   -webkit-appearance: none;
    // }
  }

  &__footer {
    .btn {
      font-size: 18px;
      padding: 1rem 3rem;
      margin: 0.25rem 1rem;
      background: linear-gradient(113.24deg, #13B497 16.01%, #05737A 106.71%);
      box-shadow: 0px 12px 23px rgb(62 73 84 / 4%);
      border-radius: 6px;
    }
  }
}
</style>
