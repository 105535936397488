
import Vue from 'vue'

export default ({
  namespaced: true,
  state () {
  	return {
      newIndividualOffer: null,
      updateProfile: null,
      updateRequest: null,
      gotoAllNotifications: false,
      notifications: [],
      totalUnreadCount: 0
    }
  },
  getters: {
    unreadCount: (state) => {
      return state.totalUnreadCount
    },
    notifications: state => state.notifications,
    newIndividualOffer: state => state.newIndividualOffer,
    updateProfile: state => state.updateProfile,
    updateRequest: state => state.updateRequest,
    gotoAllNotifications: state => state.gotoAllNotifications
  },
  actions: {
  	updateTotalUnreadCountNotifications ({ commit }, payload) {
      commit('updateTotalUnreadCountNotifications', payload)
    },
    clearNotifications ({ commit }) {
      commit('clearNotifications')
    },
    newIndividualOffer ({ commit }, payload) {
      commit('newIndividualOffer', payload)
    },
    updateProfile ({ commit }, payload) {
      commit('updateProfile', payload)
    },
    updateRequest ({ commit }, payload) {
      commit('updateRequest', payload)
    },
    gotoAllNotifications ({ commit }, payload) {
      commit('gotoAllNotifications', payload)
    },
    clearGotoAllNotifications ({ commit }, payload) {
      commit('clearGotoAllNotifications', payload)
    }
  },
  mutations: {
    viewNotification (state, ids) {
      const notifications = state.notifications
        .filter(notifications => ids.includes(notifications.id))
        .map(notification => {
          notification.reat_at = '1'
          return notification
        })
    },
    clearNotifications (state) {
      state.notifications = []
      state.totalUnreadCount = 0
    },
    updateTotalUnreadCountNotifications: (state, count) => {
      Vue.set(state, 'totalUnreadCount', count)
    },
    newIndividualOffer: (state, notification) => {
      state.newIndividualOffer = notification
    },
    updateProfile: (state, notification) => {
      state.updateProfile = notification
    },
    updateRequest: (state, notification) => {
      state.updateRequest = notification
    },
    gotoAllNotifications: (state, notification) => {
      state.gotoAllNotifications = true
    },
    clearGotoAllNotifications: (state, notification) => {
      state.gotoAllNotifications = false
    },
  },
})
