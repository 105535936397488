<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close"/>
    </div>
    <div class="modal__header">
      <div class="ttl">
        Details for {{ options.offer.name }}
      </div>
    </div>
    <div class="modal__body" v-html="options.offer.description"/>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      required: true,
    }
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    min-height: 50vh;
    max-height: 80vh;
  }

  &__close {
    .btn::before {
      content: '+';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      color: white;
      font-size: 2.25rem;
      font-weight: 300;
    }
  }

  &__header {
    padding-bottom: .75rem;
  }

  &__body {
    height: auto;
    display: block;
    padding-bottom: 1.5rem;
    white-space: break-spaces;
    overflow-y: auto;
  }
}
</style>
