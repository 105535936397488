export default {
  methods: {
  	textNotification (notification) {
  		    let authorName = notification.author.full_name ? notification.author.full_name : notification.author.member_login
	        const notificationData = notification.notification.data

	        if (notification.notification.data.type === 'chat-select-offer') {
	          return authorName + ' chat select ' + notificationData.offer_type + ' in chat with ' + notificationData.participant_names
	        }    

	        if (notification.notification.data.type === 'chat-change-offer-status') { 
	          return authorName + ' changed offer status in chat with ' + notification.notification.data.participant_names
	        }

	        if (notification.notification.data.type === 'create-service-chat') {
	          return authorName + ' applied service and created chat'
	        }

	        if (notification.notification.data.type === 'create-offer-chat') {
	          return authorName + ' applied offer and created chat'
	        }

	      	return ''
      },
  }
}