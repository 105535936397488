import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      newIndividualOffer: 'notifications/newIndividualOffer',
      updateProfile: 'notifications/updateProfile',
      updateRequest: 'notifications/updateRequest',
      gotoAllNotifications: 'notifications/gotoAllNotifications'
    }),
    layout() {
      return (this.$route.meta.requiresAuth) ? 'default-layout' : 'auth-layout'
    }
  },
  watch: {
    newIndividualOffer (notification) {
      if (notification) {
        this.$router.push({ name: "Offer" })
      }
    },
    updateProfile (notification) {
      if (notification) {
        this.$router.push({ name: "Personal" })
      }
    },
    updateRequest (notification) {
      if (notification) {
        this.$router.push({ name: "Request" })
      }
    },
    gotoAllNotifications (val) {
      if (val) {
        this.$router.push({ name: "Notifications" })
      }
    },
  }
}