<template>
  <div class="modal__wrapper">
    <div class="modal__close">
      <div class="btn" @click="close">
        <Close class="img"/>
      </div>
    </div>
    <div class="modal__header">
      <div class="ttl">
        Change password
      </div>
    </div>
    <div class="modal__body">
      <div class="form">
        <div class="form-group">
          <label>Old password</label>
          <input v-model="oldPassword" type="password" class="form-control">
          <span v-show="oldPasswordError" class="invalid-feedback">{{ oldPasswordError }}</span>
        </div>
        <div class="form-group">
          <label>New password</label>
          <input v-model="password" type="password" class="form-control">
          <span v-show="passwordError" class="invalid-feedback">{{ passwordError }}</span>
        </div>
        <div class="form-group">
          <label>Repeat password</label>
          <input v-model="repeatPassword" type="password" class="form-control">
          <span v-show="repeatPasswordError" class="invalid-feedback">{{ repeatPasswordError }}</span>
        </div>
      </div>
    </div>
    <div class="modal__footer">
      <div class="btn" @click="submit">Save data</div>
    </div>
  </div>
</template>

<script>
import Close from './../img/Close.vue'

export default {
  components: {
    Close
  },
  props: {
    options: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      oldPassword: '',
      password: '',
      repeatPassword: '',
      oldPasswordError: '',
      passwordError: '',
      repeatPasswordError: ''
    };
  },
  methods: {
    clearErrors() {
      this.oldPasswordError = ''
      this.passwordError = ''
      this.repeatPasswordError = ''
    },
    async submit() {
      const that = this
      this.clearErrors()
      if (this.oldPassword.length === 0) {
        this.oldPasswordError = 'Please enter old password'
        return
      } 
      if (this.password.length === 0) {
        this.passwordError = 'Please enter new password'
        return
      } else if (this.password.length < 6) {
        this.passwordError = 'New password must have at least 6 characters'
        return
      } else if (this.password !== this.repeatPassword) {
        this.repeatPasswordError = 'Please repeat new password'
        return
      }
      axios.post(process.env.VUE_APP_BACKEND_API_URL + 'user/password/' + this.$store.state.user.id, {
        password: this.password,
        old_password: this.oldPassword
      })
      .then(function (response) { 
        that.clearErrors()
        if (response.data.success) {
          that.close()
          that.$noty.success('Password was successfully changed')
        } else if (response.data.errors && response.data.errors.old_password && response.data.errors.old_password[0]) {
          that.oldPasswordError = response.data.errors.old_password[0]
        } else {
          that.close()
          that.$noty.error('Password was not changed')
        }
      })
      .catch((errors) => {
          console.dir(errors)
        })
    },
    close() {
      this.$emit("close");
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  }
};
</script>

<style lang="scss" scoped>
.modal {
  &__wrapper {
    width: 40rem;
  }
}
</style>
